import { ref, uploadBytes } from "firebase/storage";
import { widgetStorage } from "./firebase";

export async function uploadVoiceInput({
  file,
  widgetId,
  chatId,
}: {
  file: Blob;
  widgetId?: string;
  webAppId?: string;
  assistantId?: string;
  chatId?: string;
}) {
  const storageRef = ref(
    widgetStorage,
    `voice-inputs/widgets/${widgetId}/${chatId}/${(Math.random() * 10000000).toFixed()}.wav`
  );

  try {
    const snapshot = await uploadBytes(storageRef, file);
    return storageRef.fullPath;
  } catch (error) {
    console.error("Upload failed", error);
    throw error;
  }
}
