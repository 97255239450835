import React, { useEffect, useState } from "react";
import { WidgetLayoutWithSettings } from "./openai-widget/layout";
import WidgetChat from "./openai-widget";

import "./index.css";
import { useBoolean } from "./openai-widget/hooks/use-boolean";
import { doc, getDoc, query } from "firebase/firestore";
import { widgetDB } from "./openai-widget/lib/firebase";

export const App = ({ widgetId, isCluster }: { widgetId: string; isCluster?: boolean }) => {
  const isSharedPrototype = window.location.href.includes("platform-connection-widget");
  // const location = useP
  if (isCluster) {
  }

  const [location, setLocation] = useState(window.location.href);

  // Function to handle location changes
  const updateLocation = () => {
    setLocation(window.location.href);
  };

  useEffect(() => {
    // Listen to changes in the browser history
    window.addEventListener("popstate", updateLocation);

    // Listen to hash changes (if using hash URLs)
    window.addEventListener("hashchange", updateLocation);

    // Update location when the component mounts
    updateLocation();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("popstate", updateLocation);
      window.removeEventListener("hashchange", updateLocation);
    };
  }, []);

  const loading = useBoolean(isCluster);

  useEffect(() => {
    if (location && isCluster) {
      const widgetClusterRef = doc(widgetDB, `widgetClusters/${widgetId}`); // Create a reference to the document
      getDoc(widgetClusterRef)
        .then((doc) => {
          if (doc.exists()) {
            loading.onFalse();
          } else {
            loading.onTrue();
          }
        })
        .catch((error) => {
          console.error("Error getting widget cluster document:", error);
        });
    }
  }, [location]);

  if (loading.value) {
    return null;
  }

  return (
    <WidgetLayoutWithSettings widgetId={widgetId} defaultOpen={isSharedPrototype}>
      <WidgetChat />
    </WidgetLayoutWithSettings>
  );
};
