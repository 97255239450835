import React from "react";

import "../../utils/highlight";
// markdown plugins
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";

import Link from "@mui/material/Link";

import Image from "../image";
import StyledMarkdown from "./styles";
import { MarkdownProps } from "./types";

// ----------------------------------------------------------------------

export default function Markdown({ sx, ...other }: MarkdownProps) {
  return (
    <StyledMarkdown sx={sx}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw, rehypeHighlight, [remarkGfm, { singleTilde: false }]]}
        components={components}
        {...other}
      />
    </StyledMarkdown>
  );
}

// ----------------------------------------------------------------------

const components = {
  img: ({ ...props }) => <Image alt={props.alt} ratio="16/9" sx={{ borderRadius: 2 }} {...props} />,
  a: ({ ...props }) => {
    const isHttp = props.href.includes("http");

    return isHttp ? (
      <Link
        sx={{
          fontWeight: 'bold',
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.black,
        }}
        target="_blank"
        rel="noopener"
        {...props}
      />
    ) : (
      <Link
        href={props.href}
        {...props}
        sx={{
          fontWeight: 'bold',
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? theme.palette.common.white
              : theme.palette.common.black,
        }}
      >
        {props.children}
      </Link>
    );
  },
};
