// @mui
import {
  Avatar,
  Typography,
  Stack,
  Box,
  Link,
  styled,
  alpha,
  useTheme,
  MenuItem,
  Button,
} from '@mui/material';
// @types
import React, { useContext, useEffect } from 'react';
import { Message } from '../types';
// components
import TypingIndicator from '../components/TypingIndicator';
import { bgGradient } from '../theme/css';
import { useWidgetSettingsContext } from '../settings';
import Markdown from '../components/markdown';
import * as ReactDOM from 'react-dom/client';
import { ChatContext } from '../context';
// ----------------------------------------------------------------------

const MessageContainer = styled(Box)(({ theme }) => ({
  padding: '8px 10px',
  width: 'auto',
  maxWidth: '100%',
  borderRadius: 8,
  overflow: 'hidden',
  typography: theme.typography.body2,
  backgroundColor: theme.palette.background.neutral,
  color: theme.palette.grey[900],
}));

type Props = {
  message: Message;
  loadingMessage?: boolean;
  error?: boolean;
};

export default function ChatMessageItem({
  message,
  loadingMessage = false,
  error = false,
}: Props) {
  const theme = useTheme();
  const settings = useWidgetSettingsContext();

  const { status, sendMessage, chatId, messages } = useContext(ChatContext);

  function replaceOptionsWithMenuItems() {
    // Find all li elements with id pattern
    const selectableOptions = document.querySelectorAll(
      `[id^="selectable-option-user-widget-option"]`,
    );

    const values = Array.from(selectableOptions).map((option) => {
      const optionValue = option.getAttribute('data-value');

      return optionValue;
    });

    const listRoot = selectableOptions[0]?.parentElement;

    const tempContainer = document.createElement('ul');
    tempContainer.style.paddingLeft = '12px';
    const root = ReactDOM.createRoot(tempContainer);

    root.render(
      <>
        {values.map((optionValue) => (
          <MenuItem
            sx={{
              borderRadius: 2,
              backgroundColor: theme.palette.primary.light,
              transition: 'all 0.3s',
            }}
            onClick={() => {
              sendMessage(optionValue || '', messages);
            }}
            key={optionValue}
          >
            {optionValue}
          </MenuItem>
        ))}
      </>,
    );

    if (listRoot) {
      listRoot.replaceWith(tempContainer);
    }
  }

  useEffect(() => {
    replaceOptionsWithMenuItems();
  }, []);

  const sender = message.role;

  const senderDetails =
    sender === 'user'
      ? {
          type: 'me',
        }
      : {
          name: settings.name,
        };

  const currentUser =
    loadingMessage || error ? false : senderDetails.type === 'me';

  const firstName = settings.name;

  return (
    <Stack
      direction={settings.direction === 'rtl' ? 'row-reverse' : 'row'}
      justifyContent={currentUser ? 'flex-end' : 'unset'}
      sx={{ mb: 3, width: 1 }}
    >
      <Stack
        sx={{
          maxWidth: 1,
        }}
        spacing={1}
        alignItems="flex-end"
      >
        {/* <Typography
          noWrap
          variant="caption"
          fontWeight={500}
          sx={{
            color: "text.secondary",
            ...(!currentUser && {
              mr: "auto",
            }),
          }}
        >
          {currentUser ? "You" : firstName}
        </Typography> */}
        <MessageContainer
          sx={{
            '& *': {
              direction: settings.direction === 'rtl' ? 'rtl' : 'ltr',
            },
            boxSizing: 'border-box',
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderRadius: 2,
            ...(currentUser && {
              ...bgGradient({
                direction: '135deg',
                startColor: alpha(theme.palette.primary.light, 0.2),
                endColor: alpha(theme.palette.primary.main, 0.2),
              }),
              position: 'relative',
              color:
                theme.palette.mode === 'dark'
                  ? 'primary.lighter'
                  : 'primary.darker',
              backgroundColor: 'common.white',
            }),
            ...((loadingMessage || !currentUser) && {
              alignSelf: 'flex-start',
            }),
          }}
        >
          {loadingMessage ? (
            <Box marginLeft={0.5}>
              <TypingIndicator />
            </Box>
          ) : error ? (
            <Box marginLeft={0.5}>
              <Typography variant="body2" color="error">
                {message.content}
              </Typography>
            </Box>
          ) : (
            <Markdown>
              {/* <FormatMarkdownLinks text={message.content} /> */}
              {message.content}
            </Markdown>

            // message.content
          )}
        </MessageContainer>
      </Stack>
    </Stack>
  );
}

/**
 * Renders a formatted text with links.
 * Line breaks in the input text are also preserved.
 */

function addBreaks(str: string) {
  return str.replace(/([/&=])/g, "$1\u00AD");
}

function FormatMarkdownLinks({ text }: { text: string }) {
  const parts = text.split(/(\[[^\]]+\]\([^)]+\)|\n)/g);

  return (
    <Typography variant="body2" fontWeight={500}>
      {parts.map((part, index) => {
        if (part.startsWith("[")) {
          const matches = /\[([^\]]+)\]\(([^)]+)\)/.exec(part);
          if (matches && matches.length >= 3) {
            return (
              <Link
                key={index}
                href={matches[2]}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  overflowWrap: "break-word",
                }}
              >
                {matches[1]}
              </Link>
            );
          }
        } else if (part === "\n") {
          return <br key={index} />;
        }
        return <React.Fragment key={index}>{addBreaks(part)}</React.Fragment>;
      })}
    </Typography>
  );
}
